


































import { Component, Vue } from "vue-property-decorator";
import CamiloDetail from "@/components/CamiloDetail.vue";
import SyntheticDialog from "@/components/SyntheticDialog.vue";
import CamiloCode from "@/components/CamiloCodeSupply.vue";
import {getDetail,UserSupplyDetailApi} from "@/api/user-supply.api";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import { Skeleton,Popup } from "vant";
import { observer } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {
    SyntheticDialog,
    CamiloCode,
    CamiloDetail,
    Skeleton,
    Popup
  },
})
export default class UserSupplyEntityDetail extends Vue {
  globalState = getModule(GlobalStateStore);
  id = Number(this.$route.params.id);
  entityDetail: UserSupplyDetailApi | null = null;
  Tips = false
  dialog = false;
  skeleton = true;
  async handleRefreshData() {
    this.skeleton = true;
    this.entityDetail = await getDetail(this.id);
    InteractionLib.updateGoldBlindBoxStatus()
    this.skeleton = false;
  }
  confirmHandler(){
      this.$router.replace({
        name: "UserSupplyApplyDelivery",
        params: { id: String(this.id) },
      });
  }
  get isLogin(): boolean {
    return Boolean(this.globalState.userToken);
  }
  againHandler(){
    this.Tips = true
  }
  mounted(): void {
    observer("refreshInit", () => this.handleRefreshData());
    this.handleRefreshData();
  }
}
